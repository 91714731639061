<template>
  <div class="nav-top">
    <div class="nav-left" @click="left">
      {{ title }}
      <img src="../assets/change-house.png" v-if="showHouse">
    </div>
    <div class="nav-right" @click="skip">
      <div class="message">
        <p>{{ name }}</p>
        <p>{{ companyName }}</p>
      </div>
      <div class="nav-img">
        <img :src="img">
      </div>
    </div>
  </div>
</template>
<script>
import {getLocal} from "../utils/utils";
export  default {
  data(){
    return{
      img:require('@/assets/right.png'),
      name:'',
      companyName:''
    }
  },
  props:{
    title: {
      type: String,
      default: ''
    },
    showHouse:{
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.name = getLocal('name')
    this.companyName = getLocal('companyName')
  },
  methods:{
    left(){
       this.$emit('left')
    },
    skip(){
      this.$router.push({path:'/user'})
    }
  }
}
</script>
<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
  .nav-top{
    width: 100%;
    height: 120px;
    padding: 28px 50px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    .nav-left{
      text-align: left;
      font-size: 40px;
      font-family: Yu Gothic UI;
      font-weight: bold;
      color: #000000;
      opacity: 1;
      line-height: 68px;
      vertical-align: middle;
      img{
        height: 50px;
        width: 50px;
        vertical-align: middle;
      }
    }
    .nav-right{
      display: flex;
      text-align: right;
      vertical-align: middle;
      .message{
        vertical-align: middle;
        p:nth-of-type(1){
          font-size: 24px;
          font-family: Yu Gothic UI;
          font-weight: 400;
          color: #393C41;
          opacity: 1;
        }
        p:nth-of-type(2){
          font-size: 20px;
          font-family: Yu Gothic UI;
          font-weight: 400;
          color: #393C41;
          opacity: 1;
          margin-top: 4px;
        }
      }
      .nav-img{
        width: 50px;
        margin-left: 18px;
        display: flex;
        align-items:center;
        img{
          width: 50px;
          height: 50px;
        }
      }
    }

  }

</style>
